import { createAsyncThunk } from '@reduxjs/toolkit'
import { getData, getOne, urls } from '../../utils/Connections/AxiosInstance'

export const getClasses = createAsyncThunk(
  'classes/getClasses',
  async (params = '') => {
    const response = await getData(urls.classes(), params)
    return response.data.payload
  },
)
export const getOneClass = createAsyncThunk(
  'classes/fetchOneClass',
  async id => {
    const response = await getOne(urls.classes(), id)
    return response.data.payload
  },
)
