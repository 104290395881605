import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchClasses,
  fetchGroupSessions,
  fetchLevels,
  fetchOffers,
  fetchSubjects,
  fetchSubjectsByTeacher,
  fetchTeachers,
  fetchWebinars,
} from '../apis/contentApi'
import { getData, urls } from '../../utils/Connections/AxiosInstance'

export const fetchSessionTypes = createAsyncThunk(
  'content/fetchSessionTypes',
  async (params = '') => {
    try {
      const response = await getData(urls.sessionTypes(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

const initialState = {
  status: 'idle',
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOffers.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchSubjects.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchSubjects.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchSubjects.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchTeachers.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchClasses.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchLevels.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchLevels.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchLevels.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchWebinars.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchWebinars.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchWebinars.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchGroupSessions.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGroupSessions.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchGroupSessions.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchSessionTypes.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchSessionTypes.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchSessionTypes.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const fetchOptions = {
  offerIds: fetchOffers,
  classIds: fetchClasses,
  levelIds: fetchLevels,
  subjectId: fetchSubjects,
  subjectsOfTeacher: fetchSubjectsByTeacher,
  teacherId: fetchTeachers,
  webinar: fetchWebinars,
  group_session_id: fetchGroupSessions,
  sessionTypes: fetchSessionTypes,
}

export default contentSlice.reducer
