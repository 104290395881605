import axios from 'axios'

const BASE_URL = process.env.REACT_APP_TAKI_BASE_URL
const primaryProtectedApi = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
})

primaryProtectedApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  error => {
    Promise.reject(error)
  },
)

primaryProtectedApi.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
)

export default primaryProtectedApi
