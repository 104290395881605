import { createSlice, current } from '@reduxjs/toolkit'
import { sortByBacClasses } from '../../utils/sortClasses'
import { COLORS } from '../../constants'
import { getClasses } from '../apis/classApi'

const initialState = {
  classes: [],
  status: false,
  classError: null,
  metaClasses: {},
}

export const classesSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.classes = current(state).classes.map(classes => {
        return { ...classes, checked: action.payload }
      })
    },
    selectClassById: (state, action) => {
      state.classes = current(state).classes.map(oneClass => {
        if (oneClass.id === action.payload.id)
          return { ...oneClass, checked: action.payload.value }
        return oneClass
      })
    },
    setClasses: (state, action) => {
      state.selectedClasses = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getClasses.pending, state => {
        state.status = 'loading'
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.classes = sortByBacClasses(action.payload).map((cls, i) => {
          return {
            checked: true,
            color: i < 28 ? COLORS[i + 1].hex : '#7165EF',
            background: i < 28 ? COLORS[i + 1].background : '#EEEDFD',
            ...cls,
          }
        })
        state.metaClasses = action.payload.meta
      })
      .addCase(getClasses.rejected, (state, action) => {
        state.status = 'failed'
        state.classError = action.error.message
      })
  },
})

export const { selectAll, selectClassById, setClasses } = classesSlice.actions

export const getStoreClasses = state => state.classes.classes
export const getClassesStatus = state => state.classes.status

export default classesSlice.reducer
