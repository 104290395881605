import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createOne,
  postUpdateOne,
  specialDelete,
  updateOne,
} from '../../utils/Connections/AxiosInstance'
import { isOstedhy, isTakiacademy } from '../../utils/workspaceChecker'
import { isoDateFormatInterval } from '../../utils/customDateFormats'
import { deleteOne } from '../../utils/Connections/AxiosInstance'
import { getData } from '../../utils/Connections/AxiosInstance'
import { urls } from '../../utils/Connections/AxiosInstance'

const getAllTakiEvents = params =>
  getData(urls.events(), {
    search: `startDate:${isoDateFormatInterval(params.day)}`,
    orderBy: 'id',
    sortedBy: 'desc',
    searchJoin: 'and',
  })
const getAllOstedhyEvents = params => getData(urls.events(params.day))

const getAllEvents = isTakiacademy ? getAllTakiEvents : getAllOstedhyEvents

export const createOneEvent = createAsyncThunk(
  'events/createOneEvent',
  async data => {
    try {
      const response = await createOne(urls.events(), data)
      return response
    } catch (error) {
      console.log(error)
    }
  },
)

export const updateOneEvent = createAsyncThunk(
  'events/updateOneEvent',
  async ({ id, data }) => {
    try {
      const updateMethod = isOstedhy
        ? updateOne.bind(null, urls.updateEvent())
        : postUpdateOne.bind(null, urls.events())
      const response = await updateMethod(id, data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchEventsAsync = createAsyncThunk(
  'events/getEvents',
  async (params = {}) => {
    params = { ...params }
    const { data } = await getAllEvents(params)
    return data.payload
  },
)

export const deleteOneEvent = createAsyncThunk(
  'events/deleteOneEvent',
  async id => {
    try {
      const deleteEvent = isOstedhy ? specialDelete : deleteOne
      const response = await deleteEvent(urls.events(), id)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
