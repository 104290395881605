import { createSlice } from '@reduxjs/toolkit'

import {
  createOneEvent,
  deleteOneEvent,
  fetchEventsAsync,
  updateOneEvent,
} from '../apis/eventApi'

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    events: [],
    eventStatus: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(createOneEvent.pending, state => {
        state.eventStatus = 'loading'
      })
      .addCase(createOneEvent.fulfilled, (state, action) => {
        state.eventStatus = 'succeeded'
        state.events.push(action.payload.data)
      })
      .addCase(createOneEvent.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(updateOneEvent.pending, state => {
        state.status = 'loading'
      })
      .addCase(updateOneEvent.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(updateOneEvent.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(fetchEventsAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(fetchEventsAsync.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(deleteOneEvent.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteOneEvent.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(deleteOneEvent.rejected, (state, action) => {
        state.status = 'failed'
      }),
})

export const getEventStatus = state => state.event.eventStatus
export default eventSlice.reducer
