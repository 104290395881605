import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import _ from 'lodash'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOptions } from '../../../store/slices/contentSlice'
import { openModal } from '../../../store/slices/modalsSlice'
import {
  createOneEvent,
  fetchEventsAsync,
  updateOneEvent,
} from '../../../store/apis/eventApi'

import { getFormConfig } from '../../../utils/getFormConfig'
import {
  addAnHourToDate,
  getLocalISOString,
} from '../../../utils/customDateFormats'
import { isOstedhy, isTakiacademy } from '../../../utils/workspaceChecker'
import { getCurrentDay } from '../../../store/slices/calendarSlice'

import { toast } from 'react-toastify'
import { Dialog } from '@mui/material'
import {
  StandardInput,
  DatePicker,
  TimePicker,
  MultiSelect,
  Input,
} from '../../Inputs'
import { CustomCheckbox } from '../../Checkbox'
import { Button } from '../../Form'
import Loader from '../../Loader/Loader'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import { INPUT_TYPES } from '../../../constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Africa/Tunisia')

const ScheduleSession = ({ id, open, handleClose, data }) => {
  const [webinarHidden, setWebinarHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [activeSessionType, setActiveSessionType] = useState('event')
  const currentDay = useSelector(getCurrentDay)

  const {
    fields,
    initial_state,
    data: formData,
    schema,
  } = getFormConfig().scheduleSession
  const { register, handleSubmit, control, formState, watch, setValue, reset } =
    useForm({
      resolver: yupResolver(isTakiacademy ? schema[activeSessionType] : schema),
      defaultValues: initial_state(data),
    })

  const watchData = watch()
  const dispatch = useDispatch()

  const startDate = getLocalISOString(watchData?.date)
  const endDate = addAnHourToDate(watchData?.date, watchData?.time)

  useEffect(() => {
    setWebinarHidden(watchData?.sessionTypes?.name !== 'Webinar')
  }, [watchData.sessionTypes])

  useEffect(() => {
    reset(initial_state(data))
  }, [data, initial_state])

  useEffect(() => {
    if (watchData?.group_session_id && activeSessionType === 'hessaty') {
      setValue(
        'offerIds',
        watchData.group_session_id?.traineeship_groups?.map(group => ({
          ...group.group,
        })),
      )
      setValue(
        'levelIds',
        watchData.group_session_id?.traineeship_levels?.map(level => ({
          ...level.level,
        })),
      )
    }
  }, [watchData.group_session_id?.id, activeSessionType])

  const handleItemClick = item => {
    setActiveSessionType(item)
  }

  const renderFormInputs = () => {
    const selectFields = isTakiacademy ? fields[activeSessionType] : fields
    return selectFields?.map(field => {
      const { type, label, id, optionDisplayAttribute, icon, searchAttribute } =
        field

      switch (type) {
        case INPUT_TYPES.asyncMultiSelect:
          return (
            <Controller
              key={id}
              name={id}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultiSelect
                    key={id}
                    id={id}
                    name={id}
                    label={label}
                    onChange={onChange}
                    value={value}
                    loadOptions={fetchOptions[id]}
                    optionsSelected={watchData[id]}
                    setOption={value => onChange(id, value)}
                    searchAttribute={searchAttribute}
                    optionDisplayAttribute={optionDisplayAttribute}
                    error={
                      formState.errors[id] && {
                        state: true,
                        message: formState.errors[id].message,
                      }
                    }
                    Icon={icon}
                    placeholder='Select'
                  />
                )
              }}
            />
          )

        case INPUT_TYPES.asyncSelect:
          if (id === 'webinar' && webinarHidden && isOstedhy) {
            return null
          }
          return (
            <Controller
              key={id}
              name={id}
              control={control}
              defaultValue={watchData[id]}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  key={id}
                  id={id}
                  label={label}
                  onChange={onChange}
                  name={id}
                  value={value}
                  loadOptions={fetchOptions[id]}
                  setOption={value => onChange(id, value)}
                  optionDisplayAttribute={optionDisplayAttribute}
                  searchAttribute={searchAttribute}
                  optionsSelected={watchData[id]}
                  error={
                    formState.errors[id] && {
                      state: true,
                      message: formState.errors[id].message,
                    }
                  }
                  Icon={icon}
                  isMulti={false}
                  placeholder='Select'
                />
              )}
            />
          )
        case INPUT_TYPES.number:
          return (
            <Input
              key={id}
              label={label}
              id={id}
              name={id}
              register={register}
              placeholder={`Enter ${label}`}
              onChange={e => setValue(id, e.target.value)}
              type={'number'}
              value={watchData[id]}
              error={formState.errors[id]}
            />
          )
        case INPUT_TYPES.date:
          return (
            <Controller
              name={id}
              control={control}
              defaultValue={null}
              key={id}
              render={({
                field: { onChange, value },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  key={id}
                  name={id}
                  invalid={invalid}
                  value={value ? dayjs(value) : null}
                  width={isOstedhy ? '100%' : '50%'}
                  onChange={value => onChange(dayjs(value).$d)}
                  error={error}
                />
              )}
            />
          )
        case INPUT_TYPES.time:
          return (
            <TimePicker
              key={id}
              name={id}
              onChange={value => setValue(id, value.$d)}
              value={watchData[id] ? dayjs(watchData[id]) : null}
              error={
                formState.errors[id] && {
                  state: true,
                  message: formState.errors[id].message,
                }
              }
            />
          )
        case INPUT_TYPES.checkbox:
          if (
            isTakiacademy &&
            watchData['webinar']?.email === 'support@ostedhy.com'
          ) {
            return (
              <CustomCheckbox
                key={id}
                onChange={() => setValue(id, watchData[id] === 1 ? 0 : 1)}
                checked={!!watchData[id]}
                label={label}
              />
            )
          } else {
            return null
          }

        case INPUT_TYPES.customIteration:
          return (
            <p
              key={id}
              className='add-schedule__custom-iteration'
              onClick={() => {
                handleClose(id)
                dispatch(openModal('custom_iteration'))
              }}>
              Custom your iteration
            </p>
          )
        case INPUT_TYPES.groupSession:
          return (
            <p
              key={id}
              className='add-schedule__group-session'
              onClick={() => {
                handleClose(id)
                dispatch(openModal('group_session'))
              }}>
              Create a group session
            </p>
          )

        default:
          return null
      }
    })
  }

  const handleCloseThisModal = () => {
    handleClose(id)
  }
  const handleSubmitEvent = () => {
    const isChanged = !_.isEqual(watchData, initial_state(data))
    const selectedFormData = isTakiacademy
      ? formData[activeSessionType]
      : formData
    setIsLoading(true)
    if (!data || (data.groupSessions && !data?.name)) {
      dispatch(createOneEvent(selectedFormData(watchData, startDate, endDate)))
        .then(res => {
          if (res.payload.status === 201) {
            toast.success('Session Created Successfully')
            handleCloseThisModal()
            dispatch(fetchEventsAsync({ day: currentDay }))
            reset(initial_state())
          }
        })
        .catch(() => toast.error('Something went wrong', { autoClose: 300 }))
        .finally(() => {
          setIsLoading(false)
        })
    } else if (data && !isChanged) {
      toast.warning('Please make changes to update the Session', {
        autoClose: 1500,
      })
      setIsLoading(false)
    } else {
      dispatch(
        updateOneEvent({
          id: data?.id,
          data: selectedFormData(watchData, startDate, endDate),
        }),
      )
        .then(res => {
          if (res.payload.code === 201 || res.payload.status === 'success') {
            toast.success('Session Updated Successfully')
            handleCloseThisModal()
            dispatch(fetchEventsAsync({ day: currentDay }))
            reset(initial_state())
          }
        })
        .catch(() => toast.error('Something went wrong', { autoClose: 300 }))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCloseThisModal()
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={`ta-modal add-video-modal`}
      scroll='body'>
      <div className='add-schedule'>
        <div className='add-schedule__header'>
          <div>
            <span className='subtitle'>Schedule Session</span>
            <span className='label'>Session Name</span>
          </div>
          <span
            className='close-btn'
            onClick={() => handleCloseThisModal()}></span>
        </div>
        {isTakiacademy ? (
          <div className='toggle-session-type'>
            <p
              className={clsx('toggle-session-type__item', {
                'toggle-session-type__item--active':
                  activeSessionType === 'event',
              })}
              onClick={() => handleItemClick('event')}>
              Event
            </p>
            <p
              className={clsx('toggle-session-type__item', {
                'toggle-session-type__item--active':
                  activeSessionType === 'hessaty',
              })}
              onClick={() => handleItemClick('hessaty')}>
              Hessaty Event
            </p>
          </div>
        ) : null}

        <form onSubmit={handleSubmit(handleSubmitEvent)}>
          <StandardInput
            icon={<CalendarIcon />}
            name='name'
            id={'name'}
            placeholder='Enter your session name'
            register={register}
            required={true}
            error={
              formState.errors['name'] && {
                status: true,
                message: 'Name is required',
              }
            }
          />
          {renderFormInputs()}
          <Button
            type={'submit'}
            disabled={data && dayjs(data.start_date).isBefore(dayjs())}>
            {isLoading ? (
              <Loader />
            ) : (
              `${!data?.name ? 'Schedule' : 'Update'}  Now`
            )}
          </Button>
          {/* <p
            className='add-schedule__smart-schedule'
            onClick={() => alert('open Session Smart Schedule')}>
            Session Smart Schedule
          </p> */}
        </form>
      </div>
    </Dialog>
  )
}

export default ScheduleSession
