import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { toast } from 'react-toastify'
import closeIcon from './../../../assets/icons/modal-close.svg'
import deleteIcon from './../../../assets/images/deleteIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventsAsync } from '../../../store/apis/eventApi'
import { getCurrentDay } from '../../../store/slices/calendarSlice'
import Loader from '../../Loader/Loader'

const DeletePopup = ({ id, open, handleClose, data }) => {
  const [deleteStatus, setDeleteStatus] = useState('idle')
  const currentDay = useSelector(getCurrentDay)
  const dispatch = useDispatch()

  const handleClick = async () => {
    try {
      setDeleteStatus('pending')
      await data?.action()
      setDeleteStatus('success')
      toast.success(`${data?.type} Deleted successfully`)
      handleClose(id)
      dispatch(fetchEventsAsync({ day: currentDay }))
    } catch (error) {
      setDeleteStatus('error')
      toast.error(`Something went wrong`)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id)
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='modal'>
        <div className='modal-header'>
          <span>
            <img src={deleteIcon} alt='' />
          </span>
          <span onClick={() => handleClose(id)}>
            <img src={closeIcon} alt='' />
          </span>
        </div>
        <div className='modal-content'>
          <p>Delete This {data?.type}</p>
          <p>
            Are you sure you want to delete this {data?.type}? This action
            cannot be undone.
          </p>
        </div>
        <div className='modal-buttons'>
          <button onClick={() => handleClose(id)}>Cancel</button>
          <button onClick={handleClick}>
            {deleteStatus === 'pending' ? <Loader /> : 'Delete'}
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default DeletePopup
