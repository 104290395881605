import TakiAxios from './PrimaryProtectedAxios'
import OstedhyAxios from './SecondaryProtectedAxios'
import {
  CLIENT_ONE_URLS as TAKI_URLS,
  CLIENT_TWO_URLS as OSTEDHY_URLS,
} from '../../constants'
import { isOstedhy } from '../workspaceChecker'

const getCurrentWorkspaceAxios = () => {
  if (isOstedhy) {
    return {
      axiosInstance: OstedhyAxios,
      urls: OSTEDHY_URLS,
    }
  } else {
    return {
      axiosInstance: TakiAxios,
      urls: TAKI_URLS,
    }
  }
}

export const { axiosInstance, urls } = getCurrentWorkspaceAxios()

export const getData = (url, params = '') =>
  axiosInstance.get(`${url}`, { params })
export const getOne = (url, id) => axiosInstance.get(`${url}/${id}`)
export const getMe = url => axiosInstance.get(`${url}`)
export const updateOne = (url, id = '', item) =>
  axiosInstance.put(`${url}/${id}`, item)
export const postUpdateOne = (url, id = '', item) =>
  axiosInstance.post(`${url}/${id}`, item)
export const createOne = (url, data) => axiosInstance.post(`${url}`, data)
export const deleteOne = (url, id) => axiosInstance.delete(`${url}/${id}`)
export const specialDelete = (url, id) =>
  axiosInstance.delete(`${url}`, { data: { ids: [id] } })
