import React from 'react'

const Item = ({ item, ...props }) => {
  return (
    <div {...props} className='category__info' title={item.name}>
      {item.name}
    </div>
  )
}

export default Item
