import React from 'react'
import offerImg from '../../../../assets/images/offer-placeholder.png'
import clsx from 'clsx'

const Offer = ({ offer, classes, ...props }) => {
  return (
    <div className={clsx('offer', classes)} {...props}>
      <img
        src={offer.thumbnail || offerImg}
        alt='offer'
        className='offer__image'
      />
      <p className='offer_name'>{offer.name || 'Optimum'}</p>
    </div>
  )
}

export default Offer
