import React from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { Dialog, Divider } from '@mui/material'
import { openModal } from '../../../store/slices/modalsSlice'
import { Button } from '../../Form'
import List from './components/List'
import Label from './components/Label'
import Offer from './components/Offer'
import Teacher from './components/Teacher'
import Category from './components/Category'
import classIcon from '../../../assets/icons/modal-event-class.svg'
import levelIcon from '../../../assets/icons/event-modal-subject.svg'
import subjectIcon from '../../../assets/icons/event-modal-level.svg'
import { deleteOneEvent } from '../../../store/apis/eventApi'
import { Can } from '../../../context/AbilityContext'
import { Popover } from '../../Popover'

const Event = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch()

  const startDate = dayjs(data?.start_date).format('ddd, D MMMM, YYYY HH:mm')
  const endDate = dayjs(data?.end_date).format('HH:mm')

  const handleDeleteEvent = () => dispatch(deleteOneEvent(data.id))

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id)
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='event-modal'>
        <header className='event-modal__header'>
          <div className='desc'>
            <p className='desc__title'>{data?.name}</p>
            <p className='desc__date'>
              {startDate} - {endDate} ({data?.duration} hour)
            </p>
          </div>
          <span className='close-btn' onClick={() => handleClose(id)}></span>
        </header>
        <div className='event-modal__details'>
          <Teacher data={data?.teacher} />
          {data?.offers?.length > 0 ? (
            <Popover
              content={
                data?.offers?.length > 1 &&
                data?.offers?.map(offer => (
                  <Offer key={offer.id} offer={offer} />
                ))
              }>
              {data?.offers && data?.offers ? (
                <Offer offer={data?.offers[0]} classes={'offer'} />
              ) : null}
            </Popover>
          ) : null}
        </div>
        <Divider />
        <div className='event-modal__about'>
          <p className='session-info'>Session Information:</p>
          <Category label={'Classes'} items={data?.classes} icon={classIcon} />
          <Category
            label={'Subjects'}
            items={data?.subjects}
            icon={subjectIcon}
          />
          <div className='category'>
            <Label icon={levelIcon} label='Levels' />
            <List
              items={
                data?.levels?.length === 0
                  ? [{ id: nanoid(4), name: 'All' }]
                  : data?.levels
              }
            />
          </div>
        </div>
        <Can i='manage' a='event'>
          <footer>
            <p className='text'>Do you want to?</p>
            <div className='btns-container'>
              <Button
                cls='delete'
                onClick={() => {
                  handleClose(id)
                  dispatch(
                    openModal('remove', {
                      type: 'Event',
                      action: () => handleDeleteEvent(),
                    }),
                  )
                }}>
                Delete
              </Button>
              <Button
                cls='reschedule'
                onClick={() => {
                  handleClose(id)
                  dispatch(openModal('schedule_session', data))
                }}>
                Reschedule
              </Button>
            </div>
          </footer>
        </Can>
      </div>
    </Dialog>
  )
}

export default Event
