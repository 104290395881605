import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import extractEventData from '../../utils/extractEventData'
import { WORKSPACES } from '../../constants'
import { getData, urls } from '../../utils/Connections/AxiosInstance'
import { isoDateFormatInterval } from '../../utils/customDateFormats'

const initialState = {
  calendarFiltersStyle: 'open',
  events: [],
  currentDay: dayjs().toString(),
  status: 'idle',
}
const getAllTakiEvents = params =>
  getData(urls.events(), {
    search: `startDate:${isoDateFormatInterval(params.day)}`,
    orderBy: 'id',
    sortedBy: 'desc',
    searchJoin: 'and',
  })
const getAllOstedhyEvents = params => getData(urls.events(params.day))

const getAllEvents =
  localStorage.getItem('currentWorkspace') === WORKSPACES.TAKI.name
    ? getAllTakiEvents
    : getAllOstedhyEvents

export const fetchEventsAsync = createAsyncThunk(
  'events/getEvents',
  async (params = {}) => {
    params = { ...params }
    const { data } = await getAllEvents(params)
    return data.payload
  },
)

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    toggleCalendarFiltersStatus: (state, action) => {
      const status = action.payload
      if (status) {
        state.calendarFiltersStyle = status
      } else {
        state.calendarFiltersStyle =
          current(state).calendarFiltersStyle === 'hidden' ? 'open' : 'hidden'
      }
    },
    selectDay: (state, action) => {
      state.currentDay = action.payload
    },
    incrementDay: state => {
      const currentDay = dayjs(state?.currentDay).add(1, 'day').toString()
      state.currentDay = currentDay
    },
    decrementDay: state => {
      const currentDay = dayjs(state?.currentDay).subtract(1, 'day').toString()
      state.currentDay = currentDay
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEventsAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.status = 'success'
        state.events = action.payload.map(event => extractEventData(event))
      })
      .addCase(fetchEventsAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const getEvents = state => state.calendar.events
export const getEventsStatus = state => state.calendar.status
export const getCurrentDay = state => state.calendar.currentDay
export const getCalendarFiltersStyle = state =>
  state.calendar.calendarFiltersStyle

export const {
  toggleCalendarFiltersStatus,
  selectClassById,
  selectDay,
  incrementDay,
  decrementDay,
} = calendarSlice.actions

export default calendarSlice.reducer
