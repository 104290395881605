import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createOne,
  getData,
  getOne,
  urls,
} from '../../utils/Connections/AxiosInstance'

export const fetchOffers = createAsyncThunk(
  'content/fetchOffers',
  async (params = '') => {
    try {
      const response = await getData(urls.offers(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchLevels = createAsyncThunk(
  'content/fetchLevels',
  async (params = '') => {
    try {
      const response = await getData(urls.levels(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchClasses = createAsyncThunk(
  'content/fetchClasses',
  async (params = '') => {
    try {
      const response = await getData(urls.classes(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchSubjects = createAsyncThunk(
  'content/fetchSubjects',
  async (params = '') => {
    try {
      const response = await getData(urls.subjects(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchSubjectsByTeacher = createAsyncThunk(
  'content/fetchSubjectsByTeacher',
  async id => {
    try {
      const response = await getOne(urls.subjectsByTeacher(), id)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchTeachers = createAsyncThunk(
  'content/fetchTeachers',
  async (params = '') => {
    try {
      const response = await getData(urls.instructors(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchWebinars = createAsyncThunk(
  'content/fetchWebinars',
  async (params = '') => {
    try {
      const response = await getData(urls.webinars(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchGroupSessions = createAsyncThunk(
  'content/fetchGroupSessions',
  async (params = '') => {
    try {
      const response = await getData(urls.groupSessions(), params)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

export const createGroupSession = createAsyncThunk(
  'content/createGroupSession',
  async data => {
    try {
      const response = await createOne(urls.groupSessions(), data)
      return response
    } catch (error) {
      console.log(error)
    }
  },
)
