import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'

const DatePicker = ({
  width,
  value,
  onChange,
  error = { state: false, message: '' },
  invalid,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        value={value}
        onChange={onChange}
        onError={err => err}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          textField: {
            helperText: invalid ? error.message : null,
          },
        }}
        sx={{
          borderRadius: '12px',
          marginTop: '15px',
          width,
          '& p': {
            color: invalid && '#ed3863',
            marginLeft: '0',
          },
          fieldset: {
            borderColor: invalid && '#ed3863',
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
