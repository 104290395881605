import React, { useState } from 'react'
import {
  Dialog,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
} from '@mui/material'
import { openModal } from '../../../store/slices/modalsSlice'
import { useDispatch } from 'react-redux'
import { RepeatInput } from '../../Inputs'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import { Button } from '../../Form'
import dayjs from 'dayjs'
import { DAYS_LABELS } from '../../../constants'

const CustomIteration = ({ id, open, handleClose, data, ...rest }) => {
  const [intervalReps, setIntervalReps] = useState(null)
  const [selectedEnd, setSelectedEnd] = useState('on')
  const [endsOn, setEndsOn] = useState(null)
  const [endsAfter, setEndsAfter] = useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const dispatch = useDispatch()

  const handleSelectDay = day => {
    setSelectedDays(prev => {
      const prevCopy = [...prev]
      if (selectedDays.some(select => select.id === day.id)) {
        return prevCopy.filter(select => select.id !== day.id)
      } else {
        return [...prevCopy, day]
      }
    })
  }

  const isWeeks =
    !!intervalReps && intervalReps.selectedInterval.value === 'week'

  const handleSave = () => {
    const data = {
      days: selectedDays.map(d => d.id),
      endsAfter,
      endsOn: dayjs(endsOn).format('YYYY-MM-DD'),
      repsMethod: intervalReps.selectedInterval.value,
      repetitions: intervalReps.repetitions,
    }
    console.log(data)
    setEndsAfter(null)
    setEndsOn(null)
    setSelectedDays([])
    setIntervalReps(null)
    handleClose(id)
    dispatch(openModal('schedule_session'))
  }

  const handleCloseModal = (e, reason) => {
    if (reason === 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose(id)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='ta-modal add-video-modal '>
      <div className='custom-iteration'>
        <div className='custom-iteration__header'>
          <div>
            <span className='subtitle'>Schedule Session</span>
            <span className='label'>Session Name</span>
          </div>
          <span
            className='close-btn'
            onClick={() => {
              handleClose(id)
              dispatch(openModal('schedule_session'))
            }}></span>
        </div>
        <div className='custom-iteration__container'>
          <RepeatInput onChange={setIntervalReps} />
          <div className={clsx('day-reps', { hide: !isWeeks })}>
            <label className='label'>Repeat on</label>
            <div className='days'>
              {DAYS_LABELS.map(day => (
                <div
                  className={clsx('day', {
                    selected: selectedDays.some(select => select.id === day.id),
                  })}
                  key={day.id}
                  onClick={() => handleSelectDay(day)}>
                  {day.label}
                </div>
              ))}
            </div>
          </div>
          <div className='ends'>
            <p className='label'>Ends</p>
            <div className='option'>
              <FormControlLabel
                value='on'
                control={<Radio checked={selectedEnd === 'on'} />}
                label='On'
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                }}
                onChange={e => setSelectedEnd(e.target.value)}
              />
              <DesktopDatePicker
                disabled={selectedEnd !== 'on'}
                slots={{
                  openPickerIcon: CalendarIcon,
                }}
                placeholder='Select you date'
                format='YYYY-MM-DD'
                onChange={value => setEndsOn(value)}
                sx={{ width: '215px' }}
                inputProps={{
                  placeholder: 'Placeholder',
                }}
              />
            </div>
            <div className='option'>
              <FormControlLabel
                value='after'
                control={<Radio checked={selectedEnd === 'after'} />}
                label='After'
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                }}
                onChange={e => setSelectedEnd(e.target.value)}
              />
              <OutlinedInput
                id='outlined-adornment-weight'
                type='number'
                defaultValue={1}
                onChange={e => setEndsAfter(e.target.value)}
                disabled={selectedEnd !== 'after'}
                sx={{ width: '215px' }}
                endAdornment={
                  <InputAdornment position='end'>occurrences</InputAdornment>
                }
                aria-describedby='outlined-weight-helper-text'
              />
            </div>
          </div>
          <Button onClick={handleSave}>Save</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CustomIteration
