export const sortByBacClasses = classes =>
  classes.sort((a, b) => {
    const aStartsWithBac = a.name.toLowerCase().startsWith('bac')
    const bStartsWithBac = b.name.toLowerCase().startsWith('bac')

    if (aStartsWithBac && !bStartsWithBac) {
      return -1
    } else if (!aStartsWithBac && bStartsWithBac) {
      return 1
    } else {
      return 0
    }
  })
