import React, { useEffect, useState } from 'react'
import SelectInput from '../SelectInput/SelectInput'
import { pluralize } from '../../../utils/pluralize'
import { INTERVAL_OPTIONS } from '../../../constants'

const RepeatInput = ({ onChange }) => {
  const [repetitions, setRepetitions] = useState(1)
  const [options, setOptions] = useState(INTERVAL_OPTIONS)
  const [selectedInterval, setSelectedInterval] = useState(null)

  useEffect(() => {
    setOptions(prevOptions =>
      prevOptions.map(option => {
        return { ...option, label: pluralize(option.label, repetitions) }
      }),
    )
    if (selectedInterval) {
      setSelectedInterval(prevSelectedInterval => {
        return {
          ...prevSelectedInterval,
          label: pluralize(prevSelectedInterval.label, repetitions),
        }
      })
    }
  }, [repetitions])

  const handleSelect = option => {
    setSelectedInterval(option)
    onChange({
      repetitions,
      selectedInterval: option,
    })
  }
  const handleChange = e => {
    const value = e.target.value * 1
    setRepetitions(value)
    onChange({
      repetitions: value,
      selectedInterval,
    })
  }

  return (
    <div className='repeat-input'>
      <label htmlFor='repetitions' className='repeat-input__label'>
        Repeat Every
      </label>
      <input
        type='number'
        id='repetitions'
        className='repeat-input__reps'
        value={repetitions}
        onChange={handleChange}
        min={1}
      />
      <SelectInput
        onClick={handleSelect}
        options={options}
        cls='repeat-input__select'
        defaultValue={selectedInterval}
      />
    </div>
  )
}

export default RepeatInput
