import React from 'react'
import clsx from 'clsx'
import { Checkbox } from '@mui/material'
import { ReactComponent as EmptyCheckboxIcon } from '../../assets/icons/ckeckbox-empty.svg'
import { ReactComponent as CheckedCheckboxIcon } from '../../assets/icons/ckeckbox-checked.svg'

const CustomCheckbox = ({ cls, checked = false, onChange, label, name }) => {
  return (
    <div className='custom-checkbox'>
      <Checkbox
        className={clsx('class_item__checkbox', cls)}
        icon={<EmptyCheckboxIcon />}
        checkedIcon={<CheckedCheckboxIcon />}
        checked={checked}
        onChange={onChange}
      />
      <label>{label}</label>
    </div>
  )
}

export default CustomCheckbox
