import React from 'react'
import Item from './Item'
import { Popover } from '../../../Popover'

const List = ({ items }) => {
  return (
    <Popover
      content={
        items?.length > 1 &&
        items?.map(item => <Item key={item.id} item={item} />)
      }>
      {items ? <Item item={items[0]} classes={'item'} /> : null}
    </Popover>
  )
}
export default List
