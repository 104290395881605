import clsx from 'clsx'
import { Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebarStatus } from '../../store/slices/settingSlice'
import useWindowSize from '../../hooks/useWindowSize'
import { ReactComponent as ToggleIcon } from '../../assets/icons/menu-toggle.svg'
import { ReactComponent as TakiAcademyLogo } from '../../assets/icons/ta-logo.svg'
import { ReactComponent as TakiCalendarLogo } from '../../assets/icons/logo.svg'
import { ReactComponent as OstedhyLogo } from '../../assets/icons/ostedhy_thumbnail.svg'
import { ReactComponent as Typo } from '../../assets/icons/ta-logo-typo.svg'
import { ReactComponent as TeachersIcon } from '../../assets/icons/nav-teachers.svg'
import { ReactComponent as ClassesIcon } from '../../assets/icons/nav-classes.svg'
import { ReactComponent as SubjectsIcon } from '../../assets/icons/nav-subjects.svg'
import { ReactComponent as OffersIcon } from '../../assets/icons/nav-offers.svg'
import { ReactComponent as LevelsIcon } from '../../assets/icons/nav-levels.svg'
import { ReactComponent as StreamingToolsIcon } from '../../assets/icons/nav-streaming-tools.svg'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentWorkspace } from '../../store/slices/workspacesSlice'

const LINKS = ['/', '/members', '/activities']
const TITLES = {
  teachers: { title: 'Teachers', icon: <TeachersIcon /> },
  classes: { title: 'Classes', icon: <ClassesIcon /> },
  subjects: { title: 'Subjects', icon: <SubjectsIcon /> },
  offers: { title: 'Offers', icon: <OffersIcon /> },
  levels: { title: 'Levels', icon: <LevelsIcon /> },
  streamingtools: { title: 'Streaming Tools', icon: <StreamingToolsIcon /> },
}

const MainHeader = () => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { sidebarStyle } = useSelector(state => state.setting)
  let location = useLocation()
  const isActive = LINKS.includes(location.pathname)
  const currentWorkspace = useSelector(getCurrentWorkspace)
  const { user } = useAuthContext[currentWorkspace.name]()

  return (
    <div className={clsx('main_header', isActive && 'main_header__no_border')}>
      <div className='main_header__column main_header__left_column'>
        {width <= 800 && (
          <>
            <div
              className='main_header__toggle_btn'
              onClick={() =>
                dispatch(
                  toggleSidebarStatus(
                    sidebarStyle === 'hidden' ? 'open' : 'hidden',
                  ),
                )
              }>
              <ToggleIcon />
            </div>
            <div className='main_header__logo_container'>
              <TakiCalendarLogo className='main_logo' />
            </div>
            <Divider orientation='vertical' variant='middle' flexItem />
          </>
        )}
        {isActive ? (
          <div className='main_header__logo_container'>
            {currentWorkspace.name === 'taki' ? (
              <TakiAcademyLogo className='main_logo' />
            ) : (
              <OstedhyLogo />
            )}
            {width > 800 &&
              (currentWorkspace.name === 'taki' ? (
                <Typo className='main_header__logo_container__typo' />
              ) : (
                <div style={{ fontWeight: 'bold' }}>Ostedhy</div>
              ))}
          </div>
        ) : (
          <div className='main_header__title_container'>
            <span className='main_header__title_container__icon'>
              {TITLES[location.pathname.replace(/[/-]/g, '')]?.icon ||
                TITLES[location.state.routeTitle]?.icon}
            </span>
            <span className='main_header__title_container__title'>
              {TITLES[location.pathname.replace(/[/-]/g, '')]?.title ||
                TITLES[location.state.routeTitle]?.title}
            </span>
          </div>
        )}
      </div>
      <div className='main_header__column main_header__right_column'>
        <Divider orientation='vertical' variant='middle' flexItem />
        <div className='main_header__avatar_btn'>
          <DropdownMenu />
          {width > 800 && (
            <div className='avatar_text'>
              <span className='avatar_text__user'>{user?.username}</span>
              <span className='avatar_text__role'>{user?.role}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainHeader
